/* @import '~/bootstrap/css/bootstrap.css'; */

.navbar-inverse {
    display: flex;
    justify-content: center;
    font-family: sans-serif;
    font-weight: bold;
    border-radius: 0 !important;
    border-color: #F5F5F5 !important;
    color: black;
}

.navbar-inverse .navbar-nav>li>a {
    color: #333 !important;
}

.navbar-inverse .navbar-nav>li>a:hover {
    background-color: transparent !important;
    color: #ED502E !important;
    border-bottom: 5px solid #FFF;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.active>a:focus {
    background-color: #FFF !important;
    color: #ED502E !important;
    border-bottom: 5px solid #e61837;
}

.bootstrapRoot {
    box-shadow: 0;
    text-transform: none;
    font-size: 14px;
    padding: 6px 12px;
    border: 1px solid;
    background-color: white;
    border-color: #ED502E;
    color: #ED502E;
    font-family: 'Lato', 'sans-serif';
}

.bootstrapRoot:hover {
    background-color: #ED502E;
    border-color: #ED502E;
    border: 1px solid;
    color: #ED502E;
}

.avatar {
    margin: 10
}

@media (min-width: 768px) {
    .navbar-custom-center {
        float: none !important;
        display: flex !important;
        justify-content: center;
    }

    .p-md-5 {
        padding: 0rem !important;
    }
    .imglogo {
        margin-top: 50px !important;
    }
}

@media (max-width: 768px) {
    .navbar {
        margin-bottom: auto !important;
    }

    .imglogo {
        margin-right: 10vw !important;
        margin-top: 50px !important;
    }
}

.navbar::before {
    content: none !important;
}

.navbar::after {
    content: none !important;
}



.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: #6c757d;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(108, 117, 125, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}