@media (max-width: 580px)
 {
    .HowItWorks-txtBackImage-111{
        width: 35vw !important;
        top :25vw !important;
    }
    .HowItWorks-imgBack-109{
        height: 80% !important;
    }
    .HowItWorks-button-114{
        width: 35vw !important;
       
    }
    .HowItWorks-imgFront-110{
        left: 1% !important;
        width: 40% !important;
    }
    .HowItWorks-title-112{
        font-size: 40px !important;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) {
      
    .HowItWorks-txtBackImage-111{
        width: 35vw !important;
        top :15vw !important;
        height: 40vw;
    }

    .HowItWorks-imgFront-110{
        left: 1% !important;
        width: 40% !important;
    }
}

@media only screen and (min-width: 1910px) {
    .HowItWorks-imgFront-110{
        left: 15vw !important;
    }
}