.bg {
    background: url(/static/media/api_home_2.c432086c.jpg) center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: cover;
 height: 100vh;
    }

.footerImage {
    
    background: url(/static/media/api_footer.db0c9de4.png) center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: cover;
    height: 100vh;
}
 @media (min-width: 768px)
 {
	 .navbar {
		width: 80vw;
        left:10vw;
    }
    
    .Connect-Home--intro-1{
            padding-top: calc(100vh/4) !important;
    }
    .Connect-Home--typography-2 {
        font-size: 6vh !important;
    }
}

@media (min-width: 268px)
{
   .Connect-Home--intro-1{
           padding-top: calc(100vh/10) !important;
   }
   .Connect-Home--typography-2 {
    font-size: 6vh !important;
}
}

@media (max-width: 580px)
 {
    .HowItWorks-txtBackImage-111{
        width: 35vw !important;
        top :25vw !important;
    }
    .HowItWorks-imgBack-109{
        height: 80% !important;
    }
    .HowItWorks-button-114{
        width: 35vw !important;
       
    }
    .HowItWorks-imgFront-110{
        left: 1% !important;
        width: 40% !important;
    }
    .HowItWorks-title-112{
        font-size: 40px !important;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) {
      
    .HowItWorks-txtBackImage-111{
        width: 35vw !important;
        top :15vw !important;
        height: 40vw;
    }

    .HowItWorks-imgFront-110{
        left: 1% !important;
        width: 40% !important;
    }
}

@media only screen and (min-width: 1910px) {
    .HowItWorks-imgFront-110{
        left: 15vw !important;
    }
}
/* @import '~/bootstrap/css/bootstrap.css'; */

.navbar-inverse {
    display: flex;
    justify-content: center;
    font-family: sans-serif;
    font-weight: bold;
    border-radius: 0 !important;
    border-color: #F5F5F5 !important;
    color: black;
}

.navbar-inverse .navbar-nav>li>a {
    color: #333 !important;
}

.navbar-inverse .navbar-nav>li>a:hover {
    background-color: transparent !important;
    color: #ED502E !important;
    border-bottom: 5px solid #FFF;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.active>a:focus {
    background-color: #FFF !important;
    color: #ED502E !important;
    border-bottom: 5px solid #e61837;
}

.bootstrapRoot {
    box-shadow: 0;
    text-transform: none;
    font-size: 14px;
    padding: 6px 12px;
    border: 1px solid;
    background-color: white;
    border-color: #ED502E;
    color: #ED502E;
    font-family: 'Lato', 'sans-serif';
}

.bootstrapRoot:hover {
    background-color: #ED502E;
    border-color: #ED502E;
    border: 1px solid;
    color: #ED502E;
}

.avatar {
    margin: 10
}

@media (min-width: 768px) {
    .navbar-custom-center {
        float: none !important;
        display: flex !important;
        justify-content: center;
    }

    .p-md-5 {
        padding: 0rem !important;
    }
    .imglogo {
        margin-top: 50px !important;
    }
}

@media (max-width: 768px) {
    .navbar {
        margin-bottom: auto !important;
    }

    .imglogo {
        margin-right: 10vw !important;
        margin-top: 50px !important;
    }
}

.navbar::before {
    content: none !important;
}

.navbar::after {
    content: none !important;
}



.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: #6c757d;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(108, 117, 125, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}




.col {
    width: 50%;
}

.customcard {
    float: left;
    max-width: 360;
    height: 380;
 } 
 @media only screen and (max-width: 768px) {
    .customcard {
       width: 100%;
       height: 100%;
    }
  } 
  @media only screen and (min-width: 768px) {
    .customcard {
       width: 280px;
       padding: 0px 10px 10px 0px;
       height: 380px;
    }
  }





