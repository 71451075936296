.bg {
    background: url('../../images/api_home_2.jpg') center center;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
 height: 100vh;
    }

.footerImage {
    
    background: url('../../images/api_footer.png') center center;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}
 @media (min-width: 768px)
 {
	 .navbar {
		width: 80vw;
        left:10vw;
    }
    
    .Connect-Home--intro-1{
            padding-top: calc(100vh/4) !important;
    }
    .Connect-Home--typography-2 {
        font-size: 6vh !important;
    }
}

@media (min-width: 268px)
{
   .Connect-Home--intro-1{
           padding-top: calc(100vh/10) !important;
   }
   .Connect-Home--typography-2 {
    font-size: 6vh !important;
}
}
